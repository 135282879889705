//  引入拦截器
import axios from '@/common/js/request';

export default {
    //  获取合同列表
    getTableData(data) {
        return axios({
            url: '/contract/selectContractList',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            data
        })
    },
    //  查询合同单条数据
    getContractInfo(params) {
        return axios({
            url: '/contract/selectContractDetail',
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
            params
        })
    },
    //
    //  上传图片
    uploadImg(data){
        return axios({
            url: '/aliYun/uploadGasPatrolImgToAliYunOSS',
            method: 'post',
            ContentType: 'multipart/form-data',
            data
        })
    },
    //删除上传文件
    deleteImg(params) {
        return axios({
            url: '/aliYun/deleteImgFromAliYunOSS',
            method: 'get',
            params
        })
    },
     uploadImg1(data){ //带水印
         return axios({
            url: '/aliYun/uploadMarkImg',
            method: 'post',
            data
        })
    },
    //  新增合同
    addNewContract(data) {
        return axios({
            url:'/contract/saveContract',
            method:'post',
            headers: {
                'Content-Type': 'application/json'
            },
            data
        })
    },
    //  修改合同
    updateContract(data) {
        return axios({
            url:'/contract/updateContract',
            method:'post',
            headers: {
                'Content-Type': 'application/json'
            },
            data
        })
    },
    //  合同确认列表
    getContractConfirmList(data) {
        return axios({
            url:'/contract/selectContractConfirmList',
            method:'post',
            headers: {
                'Content-Type': 'application/json'
            },
            data
        })
    },

    // 合同确认
    confirmContract(params) {
        return axios({
            url:'/contract/contractConfirm',
            method:'GET',
            headers: {
                'Content-Type': 'application/json'
            },
            params
        })
    },
    //  查询报装工单
    getOrderInfo(params) {
        return axios({
            url:'/contract/selectApplyOrder',
            method:'GET',
            headers: {
                'Content-Type': 'application/json'
            },
            params
        })
    },
    //  合同完成
    completeContract(params) {
        return axios({
            url:'/contract/contractComplete',
            method:'GET',
            headers:{
                'Content-Type': 'application/json'
            },
            params
        })
    }
}